import {useDropzone} from 'react-dropzone'
import react, { useCallback, useEffect, useRef, useState } from 'react'
import './style.css'
import {AlertIcon, CopyTextIcon, CrownActive, CrownDisabled, CrownWhite, DownloadCertIcon, FileAdd, PositiveFeedbackIcon, PositiveFeedbackIconFill, Spinner, SubscriptionErrorIcon} from '../icons'
import { ProgressBar, } from '../progressBar';
import { getFeedback, getWordsUsed, sendFileRequest, sendRequest, sendSubscribeRequest } from '../../requests';
import { BuildSegments, buildSentenceTooltipStyle } from '../../segments/segments';
import { renderToString } from 'react-dom/server';
import {logEvent} from "../../utils";
import {createScanReportPDF} from "../../certificate";
import { Divider, Modal } from 'antd';
import { usePrivy } from '@privy-io/react-auth';

interface ITag{
    children: string;
    bordered?: boolean;
}
const Tag: react.FC<ITag> = (props) => {
    return <span className={'tag__container ' + (props.bordered ? 'tag__bordered' : "")}>{props.children}</span>
}


interface ITextarea {
    error?: string;
    text: string;
    setText: (text: string) => void;
    setFile: (file?: any) => void;
    segments: string;
    setWordsModalOpened: (value: boolean) => void;
}

interface IDisplayError{
    error: string;
}

function getErrorFontColor(errorText: string) {
    if (errorText === 'Try typing more text (>250 characters) so we can give you more accurate results') {
        return 'rgba(125, 37, 47, 1)';
    } else {
        return 'rgba(117, 81, 24, 1)'
    }
}
function getErrorBackground(errorText: string) {
    if (errorText === 'Try typing more text (>250 characters) so we can give you more accurate results') {
        return 'rgba(255, 247, 244, 1)';
    } else {
        return 'rgba(254, 247, 209, 1)'
    }
}
function getErrorBorderColor(errorText: string) {
    if (errorText === 'Try typing more text (>250 characters) so we can give you more accurate results') {
        return 'rgba(241, 200, 200, 1)';
    } else {
        return 'rgba(247, 199, 82, 1)'
    }
}

function getErrorIcon(errorText: string) {
    if (errorText === 'Try typing more text (>250 characters) so we can give you more accurate results') {
        return <AlertIcon />;
    } else {
        return <SubscriptionErrorIcon />
    }
}

export function getErrorText(errorText: string) {
    console.log(errorText);
    if (errorText === 'Try typing more text (>250 characters) so we can give you more accurate results') {
        return 'Not enough text. Please, type more than 250 characters.';
    }
    if (errorText === 'service isn’t available at the moment') {
        return 'Service is temporarily unavailable. Please, try again later.';
    }
    if (errorText === 'Only English is supported') {
        return 'Only English is supported';
    }
    if (errorText === "depscan") {
        return "You've reached your deep scan words limit, upgrade to the Premium plan to continue using deep scan mode."
    }
    else {
        return 'Free plan words limit reached. Get premium plan to increase your limits.'
    }
}

const DisplayError: react.FC<IDisplayError> = (props) => {
    return <div 
        className="alert__container"
        style={{
            backgroundColor: getErrorBackground(props.error),
            color: getErrorFontColor(props.error),
            borderColor: getErrorBorderColor(props.error)
        }}
    >
        {
            getErrorIcon(props.error)
        }
        <span 
            className='alert__text'
            style={{
                color: getErrorFontColor(props.error)
            }}
        >
            {getErrorText(props.error)}
        </span>
    </div>
}


const Textarea: react.FC<ITextarea> = (props) => {
    const {error, text, setText, setFile, segments, setWordsModalOpened} = props;
    const divRef = useRef<HTMLDivElement>(null);
    const [wordsLimit, setWordsLimit] = useState(5000);
    useEffect(() => {
        getWordsUsed().then((e) => {
            setWordsLimit(e.words_per_generation_limit);
        });
        document.querySelector('#textarea')!.addEventListener("scroll", () => {
            try {
                document.querySelectorAll('.tooltip__parent').forEach((e) => {
                    (e as any).querySelector('div').querySelector('div').style = buildSentenceTooltipStyle(false);
                    (e as any).querySelector('div').style = ''
                    document.querySelectorAll('.tooltip__parent').forEach((ee) => {
                        (ee as any).style = ''
                    });
                })
            } catch {}
        })
        document.querySelectorAll('.tooltip__parent').forEach((e) => {
            (e as any).onmouseover = () => {
                if (window.getSelection()?.toString().length !== 0) return;
                try {
                    (e as any).querySelector('div').querySelector('div').style = buildSentenceTooltipStyle(true);
                    (e as any).querySelector('div').style = `position: absolute; transform: translateY(-${(e as any).parentElement.scrollTop}px)`;
                    document.querySelectorAll('.tooltip__parent').forEach((ee) => {
                        (ee as any).style = 'background-color: white!important;'
                    });
                    (e as any).style = ''
                } catch {}
            }
            (e as any).onmouseleave = () => {
                try {
                    (e as any).querySelector('div').querySelector('div').style = buildSentenceTooltipStyle(false);
                    (e as any).querySelector('div').style = ''
                    document.querySelectorAll('.tooltip__parent').forEach((ee) => {
                        (ee as any).style = ''
                    });
                } catch {}
            }
        })

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === 'z') {
                event.preventDefault();
            }
        };
        if (divRef.current) {
            divRef.current.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            if (divRef.current) {
                divRef.current.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [segments, divRef]);

    return <div 
    className={"textarea " + (error ? 'textarea__alert-parent' : '')}
    style={error ? {
        borderColor: getErrorBorderColor(error!)
    } : {}}
>
    {
        error ?
        <DisplayError error={error}/> : <></>
    }
    {
        segments ?
        <div
        contentEditable={true}
        id="textarea"
        style={{height: 'calc(100% - 60px)', overflow: 'scroll'}}
        className={
            error?.length ?
            'textarea__alert-container' : ''
        }
        ref={divRef}
        onInput={(e: any) => {
            if (e.ctrlKey && e.key === 'z') {
                return;
            }
            var currentText = e.currentTarget.innerText.slice(0, wordsLimit);
            if (currentText === '\n') {
                setText('');
            } else {
                setText(currentText);
            }
            setFile(undefined);
        }}
        dangerouslySetInnerHTML={{__html: renderToString(<BuildSegments data={segments as any}/>)}}
    ></div> : <textarea onChange={(e) => {
            console.log(wordsLimit, e.target.value.length);
            if (wordsLimit == 5000 && e.target.value.length > 5000) {
                setWordsModalOpened(true);
            }
            setText(e.target.value.slice(0, wordsLimit));
            setFile(undefined);
        }}
        value={text}
        id='textarea'
        placeholder='Enter text or upload document to run scan (currently only English supported)'
        ></textarea>
    }
    
</div>
}

interface ITextareaWidgets{
    setFile: (file: any) => void;
    file: any;
    setText: (text: string) => void;
    text: string;
}

const TextareaWidgets: react.FC<ITextareaWidgets> = (props) => {
    const {setFile, file, setText, text} = props;
    const onDrop = useCallback((acceptedFiles: any) => {
        setFile(
            acceptedFiles[0]
        );
    }, []);
    const accept = {
        // 'application/pdf': ['.pdf'],
        // "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ['.docx'],
        'text/plain': ['.txt']
    }
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept, maxSize: 5242880/5});
    return <div className='drop__container'>
    <div className="poschange__1">
        <div {...getRootProps()} className={'filedrop__container ' + ( isDragActive ? 'filedrop__container-active' : "")}>
            <input {...getInputProps()} />
            <div className="filedrop__content" style={{borderRadius: '10px'}}>
                <FileAdd />
                <span className='upload__head'>
                    {
                        file ?
                        (file as any).path :
                        isDragActive ?
                        "Drag document here" :
                        "Click to upload document"
                    }
                </span>
                <div className="tags">
                    {/* <Tag>PDF</Tag>
                    <Tag>DOCX</Tag> */}
                    <Tag>TXT</Tag>
                    <Tag bordered>{"<1 MB"}</Tag>
                </div>
            </div>
        </div>
    </div>
    <div className="filedrop__container or__container" style={{position: 'static'}}>
        or
    </div>
    <div className="poschange__2">
        <div className='filedrop__container filedrop__container1' onClick={() => {
            navigator.clipboard.readText().then((e) => {
                setText(text+e);
            });
        }}>
            <div className="filedrop__content">
                <CopyTextIcon />
                <span className='upload__head'>
                    Click to paste text
                </span>
                <div className="tags">
                    {/* <Tag>PDF</Tag>
                    <Tag>DOCX</Tag> */}
                    <Tag bordered>{">250 characters"}</Tag>
                </div>
            </div>
        </div>
    </div>
</div>
}

interface ITextareaControll {
    text: string;
    setLoading: (status: boolean) => void;
    setPercents: (value: number) => void;
    setError: (value: string) => void;
    file: any;
    setSegments: (value: any) => void;
    setText: (value: string) => void;
    wordsModalOpened: boolean;
    setWordsModalOpened: (value: boolean) => void;
    setId: (value: number) => void;
}

export const EmailInput: React.FC<{
    email: string;
    setEmail: (value: string) => void;
    emailChange: boolean;
    setEmailChange: (value: boolean) => void;
    hasEmail: boolean;
}> = ({ email, setEmail, emailChange, setEmailChange, hasEmail }) => {
    const [isEmailChanged, setIsEmailChanged] = useState(!hasEmail);
    return (
        <div className="premium-modal__email-container">
            <input 
                type="email"
                className="disabled"
                placeholder={"Email"}
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value);
                }}
                disabled={!isEmailChanged}
            />
            {
                !isEmailChanged ?
                <p className="premium-modal__email-note">
                    Is it your current e-mail? <a href="#" className="premium-modal__link" onClick={() => {
                        setIsEmailChanged(true);
                    }}>Use different e-mail</a>
                </p> : <></>
            }
        </div>
    );
};

const usePrivyEmail = () => {
    const { user, ready } = usePrivy();
    const [email, setEmail] = useState('');
    const [hasEmail, setHasEmail] = useState(true);

    useEffect(() => {
        const fetchEmail = async () => {
            if (ready && user) {
                let userEmail = user.email?.address || '';
                if (!userEmail && user.google) {
                    userEmail = user.google.email || '';
                }
                if (userEmail) {
                    setEmail(userEmail);
                    setHasEmail(true);
                } else {
                    setHasEmail(false);
                }
            }
        };

        fetchEmail();
    }, [ready, user]);
    console.log(email, hasEmail);
    return { email, setEmail, hasEmail, setHasEmail };
};


const PremiumModal: react.FC<{open: boolean, onOk: () => void, onCancel: () => void}> = (props) => {
    const [name, setName] = useState('');
    const { email, hasEmail, setEmail, setHasEmail } = usePrivyEmail();
    const [emailChange, setEmailChange] = useState(false);
    return <Modal open={props.open} onOk={props.onOk} onCancel={props.onCancel} width={450} centered footer={null} maskClosable={false}>
        <div className="premium-modal">
            <div className="premium-modal__header">
                <div className="premium-modal__crown">
                    <CrownWhite />
                </div>
                <h2 className="premium-modal__title">Get free Premium</h2>
            </div>
            <p className="premium-modal__description">
                Dear user, we are glad to see that you're interested in our AI detector. While currently, It's AI is only a startup, and we still have a lot to accomplish, it's very important for us to be aligned with our clients and to deeply understand their needs.
            </p>
            <p className="premium-modal__offer">
                It would be really great, and we would offer you a one-month premium subscription if we could contact you to gather feedback and learn more about the purposes for which you use It's AI. Let us know if we can get in touch with you.
            </p>
            <div className="premium-modal__form">
                <input 
                    type="text" 
                    placeholder="Name" 
                    className="premium-modal__input"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                />
                <EmailInput
                    email={email}
                    setEmail={setEmail}
                    emailChange={emailChange}
                    setEmailChange={setEmailChange}
                    hasEmail={hasEmail}
                />
                <div className="premium-modal__actions">
                    <button className="secondary modal-action-button" onClick={() => {
                        props.onCancel();
                        logEvent('free_premium__remind_me_later');
                        }
                    }>
                        Remind me later
                    </button>
                    <button className="primary modal-action-button" onClick={() => {
                        props.onCancel();
                        logEvent('free_premium__enroll');
                        sendSubscribeRequest(email, name, true);
                        localStorage.setItem('enrolled', 'true');
                    }}>
                        Enroll
                    </button>
                </div>
            </div>
        </div>
    </Modal>
}

export const PremiumModal2: react.FC<{open: boolean, onOk: () => void, onCancel: () => void}> = (props) => {
    const [name, setName] = useState('');
    const { email, hasEmail, setEmail, setHasEmail } = usePrivyEmail();
    const [emailChange, setEmailChange] = useState(false);
    return <Modal open={props.open} onOk={props.onOk} onCancel={props.onCancel} width={450} centered footer={null} maskClosable={false}>
        <div className="premium-modal">
            <div className="premium-modal__header2">
                <div className="premium-modal__crown">
                    <CrownWhite />
                </div>
                <h2 className="premium-modal__title2">Subscribe</h2>
            </div>
            <p className="premium-modal__description2">
                We are glad that you've decided to get a premium plan. Leave your e-mail below and our sales team will connect you.
            </p>
            <div className="premium-modal__form">
                <input 
                    type="text" 
                    placeholder="Name" 
                    className="premium-modal__input"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                />
                <EmailInput
                    email={email}
                    setEmail={setEmail}
                    emailChange={emailChange}
                    setEmailChange={setEmailChange}
                    hasEmail={hasEmail}
                />
                <div className="premium-modal__actions2">
                    <button className="primary modal-action-button modal-action-button2" onClick={() => {
                        props.onCancel();
                        logEvent("premium_subscribe__send_request");
                        sendSubscribeRequest(email, name, false);
                        localStorage.setItem('enrolled', 'true');
                    }}
                    style={{display: 'block', width: '100%'}}
                    >
                        Send a request
                    </button>
                </div>
            </div>
        </div>
    </Modal>
}

export const PremiumModal3: react.FC<{open: boolean, onOk: () => void, onCancel: () => void}> = (props) => {
    const [email, setEmail] = useState('');
    return <Modal open={props.open} onOk={props.onOk} onCancel={props.onCancel} width={320} centered footer={null} maskClosable={false}>
        <div className="premium-modal">
            <div className="premium-modal__header premium-modal__header3">
                <div className="premium-modal__crown">
                    <CrownWhite />
                </div>
                <h2 className="premium-modal__title">Subscribe</h2>
            </div>
            <p className="premium-modal__description premium-modal__description3">
                Increase your scan limits up to 100k characters/scan with our premium subscription.
            </p>
            <div className="premium-modal__form">
            <button className="primary modal-action-button" onClick={() => {
                props.onCancel();
                window.location.replace('/main/get-subscription?force-start=true')
                // localStorage.setItem('enrolled', 'true');
            }}>
                Subscribe
            </button>
            <button className="secondary modal-action-button" onClick={props.onCancel}>
                Remind me later
            </button>
            </div>
        </div>
    </Modal>
}

const TextareaControll: react.FC<ITextareaControll> = (props) => {
    const {text, setText, setLoading, setPercents, setError, file, setSegments, wordsModalOpened, setWordsModalOpened} = props;
    
    const [modalOpened, setModalOpened] = useState(false);
    const [wordsLimit, setWordsLimit] = useState(5000);

    useEffect(() => {
       getWordsUsed().then((e) => {
            setWordsLimit(e.words_per_generation_limit);
        });
    }, []);

    const sendDataToBackend = (deep: boolean) => {
        var parsedText = text;
        parsedText = parsedText.replace(/ [\n\r]\d+\.?\d*\s*%\s*(of)?\s*AI[\n\r]/gi, '');
        setText(parsedText);
        setLoading(true);
        setPercents(-1);
        setError('');
        if (file) {
            sendFileRequest(file).then((e) => {
                props.setId(e.id);
                setPercents(Number.parseFloat(e.answer) * 100);
                setLoading(false);
                if (Number.isNaN(Number.parseFloat(e))) {
                    setError(e);
                }
                window.dispatchEvent(new Event("storage"));
            })
        } else {
            sendRequest(parsedText, deep).then((e) => {
                setLoading(false);
                if (e.answer != null) {
                    props.setId(e.id);
                    setPercents((e.answer as any) * 100);
                    if (deep) {
                        setSegments(e.segments);
                    } else {
                        setSegments('');
                    }
                }
                else {
                    setError(e.error);
                }
                window.dispatchEvent(new Event("storage"));
            })
        }
    }


    return <div className="textarea__controll">
        <PremiumModal
            open={modalOpened} 
            onOk={() => setModalOpened(false)} 
            onCancel={() => setModalOpened(false)}
        />
        <PremiumModal3
            open={wordsModalOpened} 
            onOk={() => setWordsModalOpened(false)} 
            onCancel={() => setWordsModalOpened(false)}
        />
    <div className="words__count">
        <div className="words">
            {text.length} / {wordsLimit} characters
        </div>
        <ProgressBar 
            progress={text.length / wordsLimit * 100}
        />
    </div>
    <div className="fd__btns">
        <button className='primary fd__button' onClick={() => {
            logEvent('Simple scan');
            const nonEnglishCharacters = text.replace(/[a-zA-Z\s]/g, '').length;
            const nonEnglishPercentage = (nonEnglishCharacters / text.length) * 100;
            if (nonEnglishPercentage > 70) {
                setError('Only English is supported');
                return;
            }
            if (text.length < 250) {
                setError('Try typing more text (>250 characters) so we can give you more accurate results');
                return;
            }
            sendDataToBackend(false);
            const enrolled = localStorage.getItem('enrolled');
            if (enrolled !== 'true') {
                const simpleScanCount = parseInt(localStorage.getItem('scanCount') || '0', 10);
                if (simpleScanCount == 2 || simpleScanCount == 9) {
                    setModalOpened(true);
                }
                localStorage.setItem('scanCount', (simpleScanCount + 1).toString());

            }
        }}>
            Simple scan
        </button>
        <button className='primary fd__button' onClick={() => {
            const nonEnglishCharacters = text.replace(/[a-zA-Z\s]/g, '').length;
            const nonEnglishPercentage = (nonEnglishCharacters / text.length) * 100;
            if (nonEnglishPercentage > 70) {
                setError('Only English is supported');
                return;
            }
            if (text.length < 250) {
                setError('Try typing more text (>250 characters) so we can give you more accurate results');
                return;
            }
            sendDataToBackend(true);
            logEvent('Deep scan');
            const enrolled = localStorage.getItem('enrolled');
            if (enrolled !== 'true') {
                const deepScanCount = parseInt(localStorage.getItem('scanCount') || '0', 10);
                if (deepScanCount == 2 || deepScanCount == 9) {
                    setModalOpened(true);
                }
                localStorage.setItem('scanCount', (deepScanCount + 1).toString());

            }
        }}>
            Deep scan
        </button>
    </div>
    
</div>
}

interface IScanContainer{
    loading: boolean;
    percents: number;
}


const ScanContainer: react.FC<IScanContainer> = (props) => {
    const {loading, percents} = props;
    return <div className='scan__container'>
        <div className="scan-container__content">
            <div className="s-circle human-written"></div>
            <span>Human-written</span>
        </div>
        <div className="scan-container__content">
            <div className="s-circle mixed"></div>
            <span>Mixed</span>
        </div>
        <div className="scan-container__content">
            <div className="s-circle ai-gen"></div>
            <span>AI-generated</span>
        </div>
</div>
}

interface ISpeedometr{
    score: number;
}

const Speedometr: react.FC<ISpeedometr> = (props) => {
    const {score} = props;
    return <div className='speedometr__container'>
        <img src="/speedometr.png" alt="" width={155} />
        <img src="/speedometr__arrow.png" alt="" width={70} className='speed__arrow' 
            style={{
                transform: `translateY(-7px) translateX(9.5px) rotate(${(score/100) * 180}deg)`
            }}
        />
        <div className='speedometr__text'>
            <span>Human</span>
            <span>AI</span>
        </div>
    </div>
}

const FeedbackContainer: react.FC<{score: number, id: number}> = (props) => {
    const [clicked, setClicked] = useState(-1);

    useEffect(() => {
        if (clicked !== -1) {
            getFeedback(props.id.toString(), clicked === 1 ? 'positive' : 'negative')
        }
    }, [clicked]);
    
    return <div className="feedback-with-message">
            <div className='feedback__container'>
                <div onClick={() => setClicked(1)}>
                    {
                        clicked === 1 ?
                        <PositiveFeedbackIconFill /> :
                        <PositiveFeedbackIcon />
                    }
                </div>
                <div style={{'transform': 'rotate(180deg) translateY(5px)'}} onClick={() => setClicked(0)}>
                    {
                        clicked === 0 ?
                        <PositiveFeedbackIconFill /> :
                        <PositiveFeedbackIcon />
                    }
                </div>
        </div>
        {
            clicked !== -1 ? 
            <div className="feedback-message">
                Thanks for your feedback!
            </div> :
            <></>
        }
    </div> 
}

export const FileDrop: react.FC = () => {
    const [file, setFile] = react.useState();
    const [text, setText_] = react.useState('');
    const [scannedText, setTextFinal] = react.useState('');

    const [loading, setLoading] = useState(false);
    const [percents, setPercents_] = react.useState(-1);
    const [error, setError] = useState('');
    const [segments, setSegments] = useState('');
    const [wordsModalOpened, setWordsModalOpened] = useState(false);
    const [textId, setTextId] = useState(-1);

    const setText = (text: string) => {
        setText_(text)
        setPercents_(-1)
    }
    const setPercents = (value: number) => {
        setPercents_(value)
        setTextFinal(text)
    }

    useEffect(() => {
        document.addEventListener('keydown', function(event) {
            const ta = document.getElementById('textarea')
            if (document.activeElement === ta){
                return;
            }
            if ((event.metaKey && event.key === 'v') ||  (event.ctrlKey && event.key === 'v')) {
                navigator.clipboard.readText().then((e) => {
                    setText(text+e);
                });
            }
        });
        if (!text.split('\n').join('').length) {
            setSegments('');
        }
    }, [text, setText]);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('text')) {
            setText(urlParams.get('text')!)
        }
    }, []);
    const certAvailable = !loading && percents !== -1
    return <div className='uploader__container'>

        <div>
            <Textarea 
                text={text}
                setText={setText}
                setFile={setFile}
                error={error}
                segments={segments}
                setWordsModalOpened={setWordsModalOpened}
            />
            {
                text.length ? 
                <></> :
                <TextareaWidgets
                    setFile={setFile}
                    setText={setText}
                    file={file}
                    text={text}
                />
            }
            <TextareaControll
                text={text}
                setError={setError}
                setLoading={setLoading}
                setPercents={setPercents}
                setSegments={setSegments}
                file={file}
                setText={setText}
                wordsModalOpened={wordsModalOpened}
                setWordsModalOpened={setWordsModalOpened}
                setId={setTextId}
            />
            <ScanContainer 
                loading={loading}
                percents={percents}
            />
        </div>
        <div className='result__container'>
            {
                loading && percents === -1 ?
                <div className="spin__container-b">
                    <div className="spin">
                        <Spinner />
                    </div>
                    <div className="spin__container">
                        Scanning...
                    </div>
                </div> :
                Number.isNaN(percents) || percents === -1 ?
                <span className='result__percent'>%</span> : 
                <span className='result__percent-result'>{percents.toFixed(0)}% of AI</span>
            }
            
            <Speedometr score={Math.max(0, percents)}/>
            {
                (Number.isNaN(percents) || percents === -1) && !loading ?
                <span className='result__results'>Run scan to see the results</span> : 
                !loading ?
                    percents < 40 ?
                    <div className="spin__container-b human__container-out">
                        {/* <div className="">
                            <HumanIcon />
                        </div> */}
                        <div className="human__container">
                            Human-written
                        </div>
                    </div> :
                    percents < 60 ?
                    <div className="spin__container-b mixed__container-out">
                        {/* <div className="">
                            <HumanIcon />
                        </div> */}
                        <div className="mixed__container">
                            Mixed
                        </div>
                    </div> :
                    <div className="spin__container-b ai-gen__container">
                        <div className="red-container">
                            AI-generated
                        </div>
                    </div> :
                    <></>
            }
            {
                !loading && percents !== -1 && !Number.isNaN(percents) && percents >= 0 && percents <= 100 && textId !== -1 ?
                <FeedbackContainer score={percents} id={textId}/> :
                <></>
            }
            <button style={{marginTop: "auto", display: "flex"}} className={(certAvailable && "primary" || "secondary") + ' fd__button'} onClick={() => {
                if(certAvailable) {
                    logEvent('Download certificate');
                    createScanReportPDF(text, percents, 100-percents, !!segments.length)
                }
            }}>
                <DownloadCertIcon/>
                <div style={{marginLeft: "5px"}}/>Certificate
            </button>
            <div className={"certificate_footer"}>

            </div>

        </div>
    </div> 
}