import React, {useState, useEffect, FC} from 'react';
import {getHistoryRequest} from '../../../../requests';
import { ChevronIcon, CopyIcon } from '../../../../elements/icons';
import './styles.css';
import { Selector } from '../../../../elements/selector';
import { prettyDate } from '@based/pretty-date';
import {message} from 'antd';

const transformDateTime = (date: Date) => {
  const now = new Date();
  const inputDate = new Date(date);
  
  if (inputDate.toDateString() === now.toDateString()) {
    const time = inputDate.toLocaleTimeString('en-US', { 
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
    return {
      monthDay: 'Today',
      time: time
    };
  }

  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);
  if (inputDate.toDateString() === yesterday.toDateString()) {
    const time = inputDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit', 
      hour12: false
    });
    return {
      monthDay: 'Yesterday',
      time: time
    };
  }

  const month = inputDate.toLocaleString('en-US', { month: 'short' });
  const day = inputDate.getDate();
  const time = inputDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });

  return {
    monthDay: `${month}, ${day}`,
    time: time
  };
}


const useGetHistory = () => {
  const [history, setHistory] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const getHistory = async () => {
    setLoading(true);
    const data = await getHistoryRequest(page, pageSize);
    setHistory(data.results);
    setTotalPages(data.count);
    setLoading(false);
  }

  useEffect(() => {
    getHistory();
  }, [page, pageSize]);

  return {history, loading, error, page, setPage, totalPages, setPageSize, pageSize};
}


export default function HistoryPage() {
  const {history, loading, error, page, setPage, totalPages, setPageSize, pageSize} = useGetHistory();
  return <div>
    {
      loading || !history ? <div>Loading...</div> :
      error ? <div>Error</div> :
      <History items={history} page={page} setPage={setPage} totalPages={totalPages} setPageSize={setPageSize} pageSize={pageSize} />
    }
  </div>;
}

function History({items, page, setPage, totalPages, setPageSize, pageSize}: {
  items: any[], 
  page: any, 
  setPage: any, 
  totalPages: any, 
  setPageSize: any,
  pageSize: number
}) {
  const [messageApi, contextHolder] = message.useMessage();

  return <div className="centered">
    {contextHolder}
      <div className="history-content">
          <div className="history-header-container">
            <div className='history-header'>History</div>
            <div className="pag-ext"><PaginationSettings 
              total={totalPages} 
              setPageSize={setPageSize}
              pageSize={pageSize}
            /></div>
          </div>
          <div className='history-page'>
            {items.map((item) => (
              <HistoryItem item={item} onCopy={() => {
                messageApi.open({
                  type: 'success',
                  content: 'message copied to clipboard!',
                  duration: 2,
                });
              }} />
            ))}
        </div>
        <Pagination total={totalPages} pageSize={pageSize} current={page} setCurrentPage={setPage}/>
      </div>
  </div> 
}


const HistoryItem = ({item, onCopy}: {item: any, onCopy: () => void}) => {
  const [expanded, setExpanded] = useState(false);

  const text = expanded ? item.text : item.text.slice(0, 70) + '...';
  const timeObject = transformDateTime(new Date(item.date_created));

  return (
    <div className='history-item' style={{display: 'flex'}}>
      <div className="history-content-date" onClick={() => {setExpanded(!expanded)}} style={{cursor: 'pointer'}}>
        <div style={{transform: expanded ? 'rotate(90deg)' : '', width: '20px', height: '20px'}}>
          <ChevronIcon />
        </div>
        <span style={{width: '150px', display: 'inline-block', marginLeft: '10px'}}>
          <span>{(timeObject as any).monthDay} </span>
          <span className='history-content-date-time'>{(timeObject as any).time}</span>
        </span>
      </div>
      <div className="history-content-text">
        {text}
      </div>
      <div className="history-item-tools">
        <div style={{cursor: 'pointer'}} onClick={() => {
          navigator.clipboard.writeText(item.text);
          onCopy();
        }}><CopyIcon /></div>
        <button className="secondary history-btn" onClick={() => {
          window.location.href = `/main/scan?text=${encodeURIComponent(item.text)}`;
        }}>Scan again</button>
        <AIWidget score={item.answer * 100}/>
      </div>
    </div>
  );
}

const Pagination = (props: {total: number, pageSize: number, current: number, setCurrentPage: any}) => {
  const totalPages = Math.ceil(props.total / props.pageSize);
  const [pages, setPages] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const newPages = [];
    
    // First page
    newPages.push(
      <button 
        key="first"
        className="secondary small" 
        onClick={() => props.setCurrentPage(1)}
        disabled={props.current === 1}
      >
        «
      </button>
    );
  
    // Previous page
    newPages.push(
      <button
        key="prev"
        className="secondary small"
        onClick={() => props.setCurrentPage(props.current - 1)}
        disabled={props.current === 1}
      >
        ‹
      </button>
    );

    let startPage = Math.max(1, props.current - 2);
    let endPage = Math.min(totalPages, startPage + 4);
    
    if (endPage - startPage < 4) {
      startPage = Math.max(1, endPage - 4);
    }

    for (let i = startPage; i <= endPage; i++) {
      newPages.push(
        <button
          key={i}
          className={`secondary small ${props.current === i ? 'primary' : ''}`}
          onClick={() => props.setCurrentPage(i)}
        >
          {i}
        </button>
      );
    }

    // Add ellipsis and last page if needed
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        newPages.push(
          <span key="ellipsis" style={{padding: '0 4px'}}>...</span>
        );
      }
      newPages.push(
        <button
          key={totalPages}
          className={`secondary small ${props.current === totalPages ? 'primary' : ''}`}
          onClick={() => props.setCurrentPage(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    // Next page
    newPages.push(
      <button
        key="next"
        className="secondary small"
        onClick={() => props.setCurrentPage(props.current + 1)}
        disabled={props.current === totalPages}
      >
        ›
      </button>
    );
  
    // Last page
    newPages.push(
      <button
        key="last"
        className="secondary small"
        onClick={() => props.setCurrentPage(totalPages)}
        disabled={props.current === totalPages}
      >
        »
      </button>
    );

    setPages(newPages);
  }, [props.current, totalPages, props.setCurrentPage]);
  
  return (
    <div style={{display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'center', margin: '20px 0'}}>
      {pages}
    </div>
  );
}

interface IAIWidget {
  score: number;
}
const AIWidget = (props: IAIWidget) => {
  const {score} = props;

  return <div className={'score ' + (score <= 40 ? 'success-score' : score <= 60 ? 'warning-score' : 'danger-score')}>{score.toFixed(0)}% AI</div>
}


interface IPagination {
  total: number;
  setPageSize: (pageSize: number) => {};
  pageSize: number;
}
const PaginationSettings: FC<IPagination> = (props) => {
  return <div className='pagination-settings__container'>
    <span>Display results</span>
    <Selector options={['10', '20', '50']} onSelect={(a) => {
      props.setPageSize(Number.parseInt(a));
    }} sharp
    active={props.pageSize.toString()}
    />
    <span>out of {props.total}</span>
  </div>
}