import react, { useState } from 'react'
import { ArrowIcon } from '../icons'
import './style.css'

interface IOption{
    title: string;
    setActive: (state: boolean) => void;
}
export const Option: react.FC<IOption> = (props) => {
    return <div className='option' onClick={() => {
        props.setActive(false);
    }}>{props.title}</div>
}


interface ISelector {
    onSelect: (option: string) => void;
    options: string[];
    sharp?: boolean;
    active?: string;
}

export const Selector: react.FC<ISelector> = (props) => {

    const [active, setActive] = useState(false);
    const [activeOption, setActiveOption] = useState(props.active ? props.active : props.options[0]);


    return <div className={'selector__container ' + 
        (active ? 'selector__container-active ' : '') + 
        (props.sharp ? 'selector__container-sharp' : '')
    }
    >
        <div className="active-selector__container" onClick={() => {
            setActive(!active);
        }}>
            <span>{activeOption}</span>
            <div className={"arrow__icon " + (active ? "arrow__icon-active" : "")}>
                <ArrowIcon />
            </div>
        </div>
        <div className={"options " + (active ? "options__active" : "")}>
            <div className="options-container">
                {
                    props.options.map(e => <Option 
                        title={e} 
                        setActive={(state: boolean) => {
                            setActive(state);
                            setActiveOption(e);
                            props.onSelect(e);
                        }}/>
                    )
                }
            </div>
            
        </div>
    </div>
}