import react, {useEffect, useState} from 'react'
import {PrivyProvider} from '@privy-io/react-auth';
import {RouterProvider} from 'react-router-dom';
import router from '../../router'
import './style.css'
import MobileScreen from '../mobileScreen';
import {PRIVY_ID} from "../../config";
import {utmRequest} from "../../requests";


const theme = {
    color: {
        primary: "#7C93F7"
    }
}

export const Application: react.FC = () => {
    const [suffix, setSuffix] = useState('');
    const [update, setUpdate] = useState(false);
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        console.log(params);
        const fromParam = params.get('from');
        if (fromParam) {
            localStorage.setItem('from', fromParam);
        }
        if (params.get("token")) {
            localStorage.setItem('token', params.get("token")!);
            params.delete("token");
            window.location.search = params.toString();
            window.location.assign(window.location.pathname + "?" + params.toString());
        }

        if (params.has("utm_source")) {
            const utm = {
                "utm_source": params.get("utm_source"),
                "utm_medium": params.get("utm_medium"),
                "utm_campaign": params.get("utm_campaign"),
                "utm_term": params.get("utm_term"),
                "utm_content": params.get("utm_content"),
            }
            localStorage.setItem("utm", JSON.stringify(utm))
            utmRequest(utm)
        }

    }, []);
    useEffect(() => {
        var splittedHref = window.location.href.split('/')
        setSuffix(splittedHref[splittedHref.length - 1]);
        window.onhashchange = () => {
            setUpdate(!update);
        }
        
    }, [update]);

    const isMobile = window.innerWidth <= 768;
    return <PrivyProvider
        appId={PRIVY_ID || ""}
    >
        <div className='app__container'>
            {isMobile ? (
                <MobileScreen/>
            ) : (
                <>
                    <RouterProvider router={router}/>
                </>
            )}
        </div>
    </PrivyProvider>
}