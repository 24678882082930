import react, {useEffect, useRef, useState} from 'react'
import {useLogin, usePrivy} from '@privy-io/react-auth'
import {getToken, mapAnonUser, signUp} from '../../../requests';
import {APITable, APIUsage, Layout} from './layout';
import {Sidebar} from '../../../elements/sidebar';
import {PremiumPage} from './PremiumPage';
import {Britishizer} from "./britishizer";
import {logEvent} from "../../../utils";
import HistoryPage from './history';
import {Puff} from 'react-loader-spinner'
import styled from "styled-components";

export const getLogin = (user: any) => {
    if (user == null) return "  ";
    if (user.email) return user.email.address;
    if (user.wallet) return user.wallet.address;
    if (user.github) return user.github.username;
    if (user.google) return user.google.email;
    return "";
}

const getRegisteredSource = (user: any) => {
    if (user.email) return "Email";
    if (user.wallet) return "Wallet";
    if (user.github) return "Github";
    if (user.google) return "Google";
    return "Unknown";
}


const setupBackendToken = async (user: any) => {
    const login = getLogin(user);
    const e = await getToken(user.id, login, getRegisteredSource(user))
    localStorage.setItem('token', e.token);
    if (localStorage.getItem('from')) {
        mapAnonUser(localStorage.getItem('from')!).finally(() => {
            localStorage.removeItem('from');
        });
    }
    logEvent("User login", user)
    return e.token
}


export const useSystemLogin = () => {
    const {authenticated, ready, user} = usePrivy();
    const wasRequest = useRef(false);
    const [token, setToken] = useState(localStorage.getItem("token") || null);

    const {login} = useLogin({
        onComplete: (user, isNewUser, wasAlreadyAuth) => {
            var login = getLogin(user);
            if (isNewUser) {
                // @ts-ignore
                window.fbq('track', 'Lead');
            } else {
                // @ts-ignore
                window.fbq('track', 'ViewContent');
            }

            console.log("Privy user", user, "isNewUser", isNewUser, "wasAlreadyAuth", wasAlreadyAuth);

            if (wasAlreadyAuth) return;
            if (localStorage.getItem('token') != null) return;
            if (isNewUser) {
                logEvent("New user login")
                signUp(user.id, login, getRegisteredSource(user)).then((e) => {
                    localStorage.setItem('token', e);
                    setToken(e);
                    if (localStorage.getItem('from')) {
                        mapAnonUser(localStorage.getItem('from')!).finally(() => {
                            localStorage.removeItem('from');
                        });
                    }
                    logEvent("new_user_sign_up", user)
                });
            } else {
                setupBackendToken(user).then(token => setToken(token));
            }
        },
        onError: (e) => {
            logEvent("Login error")
            setTimeout(() => {
                window.location.reload();
            }, 50);
        }
    });
    useEffect(() => {
        // if (token != null) {
        //     console.log("Have token")
        //     return
        // }
        if (!authenticated && ready && !wasRequest.current) {
            wasRequest.current = true;
            login();
        } else if (authenticated && !token) {
            setupBackendToken(user).then(token => setToken(token));
        }
    }, [authenticated, login, ready, user]);

    return {ready, authenticated, tokenReady: token != null};
}

const CenterContainer = styled.div`
  display: flex;
  width: 95vw;
  height: 95vh;
  justify-content: center;
  align-items: center;
`


export const Main: react.FC = () => {
    const {ready, authenticated, tokenReady} = useSystemLogin();

    var pathname = window.location.pathname.split('/');
    var lastpath = pathname[pathname.length - 1];
    if (lastpath === 'main' || lastpath.length === 0) {
        window.location.replace('/main/scan')
    }
    if (lastpath === 'docs') {
        window.open('https://docs.its-ai.org')
    }
    if (!ready && !tokenReady) {
        return <CenterContainer><Puff width="200" color="grey"/></CenterContainer>
    }
    if (!tokenReady) {
        return <>Loading...</>
    }
    return <>
        <Sidebar/>
        {
            lastpath === 'scan' ?
                <Layout/> :
                lastpath === 'subs' ?
                    <APIUsage/> :
                    lastpath === 'get-subscription' ?
                        <PremiumPage/> :
                        lastpath == 'britishizer' ?
                            <Britishizer/> :
                            lastpath === 'history' ?
                                <HistoryPage/> :
                                <APITable/>
        }
    </>
}