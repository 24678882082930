import {ENV_NAME, googleEvent} from "./config";
import {sendEventRequest} from "./requests";


export const logEvent =  async (eventName, options = {}) => {
    const evName = eventName.toLowerCase().replaceAll(" ", "_");
    console.log("evName", evName)
    const googleEventName = "app_" + ENV_NAME.toLowerCase() + "_" + evName;
    googleEvent(googleEventName, options);
    await sendEventRequest(googleEventName, options);
}